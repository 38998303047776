import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import React, { Suspense, useEffect, useState } from "react";
import { ConfirmProvider } from "material-ui-confirm";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import GlobalLoader from "./components/GlobalLoader/GlobalLoader";
import UserContext from "./contexts/UserContext";
/** Get current user so get by local storage */
import { getCurrentUser } from "./utils/getCurrentUser";

import { setUserLoginData } from "./redux/userSlice";
import { getCountryData } from "./redux/countrySlice";
import { ToastContainer } from "react-toastify";
import GrantModal from "./components/Tags/GrantModal";
const OperationalMetrics = React.lazy(() => import("./pages/OperationalMetrics/OperationMetrics"))
const Layout = React.lazy(() => import("./components/Layout/Layout"));
const Login = React.lazy(() => import("./pages/Login/Login"));
const TaskOverview = React.lazy(() =>
  import("./pages/TaskOverview/TaskOverview")
);
const CreatePitch = React.lazy(() => import("./pages/CreatePitch/CreatePitch"));
const Influencers = React.lazy(() => import("./pages/Influencers/Influencers"));
const NotFound = React.lazy(() => import("./pages/NotFound/NotFound"));
const TaskPage = React.lazy(() => import("./pages/TaskPage/TaskPage"));
const UserList = React.lazy(() => import("./pages/UserList/UserList"));
const CostSheet = React.lazy(() => import('./pages/CostSheet/CostSheet'))
const AccountRotation = React.lazy(() => import('./pages/AccountRotation/AccountRotation'))
const AudienceApproval = React.lazy(() => import('./pages/AudienceApproval/AudienceApproval'))
const theme = createMuiTheme({
  palette: {
    background: {
      default: "#f8fcff",
    },
    primary: {
      main: "#1c75bb",
    },
    secondary: {
      main: "#c42f30",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    button: {},
  },
});

function App() {
  const [mytheme, setMyTheme] = useState(theme);
  const dispatch = useDispatch();
  const [isAuth, setIsAuth] = useState(true);
  const allUsersStatus = useSelector((state) => state.allUsersData.status);
  const allUsersData = useSelector((state) => state.allUsersData.users);
  useEffect(() => {
    /** Here we are check current user and auth token */
    const token = localStorage.getItem("authToken");
    const currentUser = localStorage.getItem("currentUser");
    if (!token || !currentUser) {
      setIsAuth(false);
    }

  }, []);

  useEffect(() => {
    setMyTheme(theme);
  }, []);

  useEffect(() => {
    if (isAuth && localStorage.authToken) {
      //dispatch(setUserLoginData(JSON.parse(localStorage.currentUser)));
      //dispatch(getCountryData());
      if (allUsersStatus === "success") {
        const user_id = JSON.parse(localStorage.currentUser).user_id;
        const Cur_user_data = allUsersData.filter((userD) => userD.id === user_id);
        dispatch(setUserLoginData(Cur_user_data[0]));
      }
    }
  }, [dispatch, isAuth, allUsersStatus]);




  return (
    <ThemeProvider theme={mytheme}>
      <ConfirmProvider>
        <CssBaseline />
        <ToastContainer />
        <BrowserRouter>
          <UserContext.Provider value={{ isAuth, setIsAuth }}>
            <div className="App">
              <Suspense fallback={<GlobalLoader size={20} color="inherit" />}>
                <Switch>
                  <Route exact path="/get-cost-input/:id" component={CostSheet} />
                  <Route exact path="/reset-password/:encodedType/:passwordToken" component={(props) => <Login statusType="changePassword" />} />
                  <Route path="/login" exact component={(props) => <Login statusType="allService" />} />
                  {(isAuth) ? (
                    <Layout>
                      <Switch>
                        <Route path="/" exact component={TaskOverview} />
                        <Route path="/create" component={CreatePitch} />
                        <Route path="/discover" component={Influencers} />
                        <Route path="/projects/:id" component={TaskPage} />
                        <Route path="/users" component={UserList} />
                        <Route path="/operational-metrics" component={OperationalMetrics} />
                        <Route path="/account-rotation" component={AccountRotation} />
                        <Route path="/audience-approval" component={AudienceApproval} />
                        <Route exact path="/grant-project-access/:projectId/:userId" component={GrantModal} />
                        {/* <Route path="/tokens" component={UsersToken} /> */}
                        <Route path={"*"} component={NotFound} />

                      </Switch>
                    </Layout>
                  ) : (
                    <Redirect to="/login" />
                  )}
                </Switch>
              </Suspense>
            </div>
          </UserContext.Provider>
        </BrowserRouter>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

export default App;
